import React from 'react';
import './Input.css'

class Input extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            input: {}
        }
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({input: {...this.state.input, [name]: value}})
    }

    render(){
        return( 
            <form className="form-element" method='POST' name='contact'>
                <input type="hidden" name="form-name" value="contact" />
                <label htmlFor="name">Name:</label>
                <input className="input-field" type='text' placeholder='Name' name='name' value={this.state.input.name || ''} onChange={this.handleChange}/>
                <label htmlFor="email">Email:</label>
                <input className="input-field" type='email'placeholder='Email' name='email' value={this.state.input.email || ''} onChange={this.handleChange}/>
                <label htmlFor="name">Message:</label>
                <textarea rows='10' name='message' value={this.state.input.message || ''} onChange={this.handleChange}/>
                <input type='submit' value='Send message!'/>
            </form>
        );
    }
}

export default Input;