import React from 'react';
import './Contact.css';
import Input from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';

class Contact extends React.Component {
    render(){
        return(
            <div className='contact-container'>
                <section id="contact-section">
                    <h1 className='contact-header'>Contact me</h1>
                    <div className='contact-section-content'>
                        <div className='left'>
                            <h2 className='contact-header'>Feel free to contact me!</h2>
                            <p>(Even if it's just to say hi)</p>
                            <Input></Input>
                        </div>
                        <div className='divider'></div>
                        <div className='right'>
                            <h2 className='contact-header'>Other ways to contact me</h2>
                            <p>(If you don't want to use a random form on a website)</p>
                            <div className='socials-container'>
                                <a className='social-link' href='mailto:yourikarijo1405@gmail.com' target='_blank' rel='noreferrer'>
                                    <FontAwesomeIcon icon={faEnvelope} className='social-icon'/>
                                    <p>Email: yourikarijo1405@gmail.com</p>
                                </a>
                                <a className='social-link' href='https://www.instagram.com/yourrriiiiii' target='_blank' rel='noreferrer'>
                                    <FontAwesomeIcon icon={faInstagram} className='social-icon' />
                                    <p>Instagram: @yourrriiiiii</p>
                                </a>
                                <a className='social-link' href='https://www.github.com/Your1405' target='_blank' rel='noreferrer'>
                                    <FontAwesomeIcon icon={faGithub} className='social-icon'/>
                                    <p>Github: Your1405</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Contact;