import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function ScrollToTop (props) {

    const toTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    };

    return ( 
        <button className='scroll-to-top' onClick={toTop}><FontAwesomeIcon icon={faArrowUp}/></button>
     );
}

export default ScrollToTop ;