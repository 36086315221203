import React from 'react';
import wave from '../images/about-me-wave.png';
import aboutImage from '../images/about-image.JPG';
import './About.css';

class About extends React.Component {
    render(){
        return(
            <>
                <section id="about-section">
                    <div className="about-image-container">
                        <img src={aboutImage} alt='' className='about-image'></img>
                    </div>
                    <div className="about-text-container">
                        <h1>About me:</h1>
                        <p>I'm Youri. I'm a 19 year old software developer based in Paramaribo, Suriname. I specialize in web development.</p>
                        <p>I am currently a software development student at NATIN-MBO. I also do mobile application and desktop development.</p>
                        <p>My goal is to create high quality software for my clients and to also learn alot in the process.</p>
                        <p>Beside coding, I also like photography and gaming. (check out my <a href="https://www.instagram.com/yourrriiiiii" target='_blank' rel='noreferrer'><u>instagram</u></a>)</p>
                        <p>You can read more about my skills <a href="#skills-section"><u>down below:</u></a></p>
                    </div>
                    <div className='ball about-ball' data-number='about-1'></div>
                    <div className='ball about-ball' data-number='about-2'></div>
                    <div className='ball about-ball' data-number='about-3'></div>
                </section>
                <img src={wave} alt='' className='wave'></img>
            </>
        );
    }
}

export default About;