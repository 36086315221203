import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

class Footer extends React.Component {
    render(){
        return(
            <footer className='footer-container'>
                <div className='footer-element'>
                    <h2 >Youri Karijopawiro</h2>
                    <nav className='footer-navigation'>
                        <a href='#about-section'>About</a>
                        <a href='#skills-section'>Skills</a>
                        <a href='#contact-section'>Contact</a>
                    </nav>
                </div>
                <div className='footer-element'>
                    <p>&copy; 2023 Youri Karijopawiro. All rights reserved</p>
                    <div className='footer-social-media' style={{"alignItems": "center"}}>
                        <a href='mailto:yourikarijo1405@gmail.com' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faEnvelope} /></a>
                        <a href='https://www.instagram.com/yourrriiiiii' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faInstagram} /></a>
                        <a href='https://www.github.com/Your1405' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faGithub}/></a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;