import './App.css';
import Header from './sections/Header';
import Banner from './sections/Banner';
import About from './sections/About';
import Skills from './sections/Skills';
import Contact from './sections/Contact/Contact';
import Footer from './sections/Footer';
import ScrollToTop from './ScrollToTop';
import { useEffect, useState } from 'react';

function App() {
  const [ scrollToTopVisible, setscrollToTopVisible ] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 600) {
        setscrollToTopVisible(true);
      } else {
        setscrollToTopVisible(false);
      }
    });
  }, []);

  return (
    <>
      <Header/>
      <Banner/>
      <About/>
      <Skills/>
      <Contact/>
      <Footer/>
      {
        scrollToTopVisible ? <ScrollToTop /> : <></>
      }
    </>
  );
}

export default App;