import React from 'react';
import './Skills.css';
import { faCode, faTerminal, faCube } from '@fortawesome/free-solid-svg-icons';
import { faHtml5, faCss3Alt, faJs, faReact, faPhp, faNodeJs, faJava, faLaravel, faAngular, faAndroid } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 

class Skills extends React.Component {
    render(){
        return(
            <div className='skills-container' id="skills-section">
                <h1>My Skills</h1>
                <section className='skills-section'>
                    <div className='skill-container' id="frontend">
                        <FontAwesomeIcon icon={faCode} className="skill-header-icon"/>
                        <h2>Frontend Development</h2>
                        <div className='skills'>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faHtml5} className='skill-icon'/>
                                <p>HTML</p>
                            </div>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faCss3Alt} className='skill-icon'/>
                                <p>CSS</p>
                            </div>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faJs} className='skill-icon'/>
                                <p>Javascript</p>
                            </div>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faReact} className='skill-icon'/>
                                <p>React</p>
                            </div>
                        </div>
                    </div>
                    <div className='skill-container' id="backend">
                        <FontAwesomeIcon icon={faTerminal} className="skill-header-icon"/>
                        <h2>Backend Development</h2>
                        <div className='skills'>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faPhp} className='skill-icon'/>
                                <p>PHP</p>
                            </div>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faNodeJs} className='skill-icon'/>
                                <p>NodeJS</p>
                            </div>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faJava} className='skill-icon'/>
                                <p>Java</p>
                            </div>
                        </div>
                    </div>
                    <div className='skill-container' id="frameworks">
                        <FontAwesomeIcon icon={faCube} className="skill-header-icon"/>
                        <h2>Frameworks</h2>
                        <div className='skills'>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faLaravel} className='skill-icon'/>
                                <p>Laravel</p>
                            </div>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faAngular} className='skill-icon'/>
                                <p>Angular</p>
                            </div>
                            <div className='skill'>
                                <FontAwesomeIcon icon={faAndroid} className='skill-icon'/>
                                <p>Mobile Application Development</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Skills;