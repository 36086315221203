import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Header.css'

class Header extends React.Component {

    constructor(){
        super();
        this.state = {
            navVisible: true
        }
    }

    toggleMenu = () => {
        let navMenu = document.getElementById('header-section');
        if(this.state.navVisible){
            this.setState({navVisible: false});
            navMenu.style.display = 'flex';
        } else {
            this.setState({navVisible: true});
            navMenu.style.display = 'none';
        }
    }

    render(){
        return (
            <header className='header-container'>
                <h1 className='header-text'>Youri Karijopawiro</h1>
                <div className='header-section' id='header-section'>
                    <nav className='nav-links'>
                        <a href='#about-section'>About</a>
                        <a href='#skills-section'>Skills</a>
                        <a href='#contact-section'>Contact</a>
                    </nav>
                    <div className='header-section social-media-links'>
                        <a href='mailto:yourikarijo1405@gmail.com' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faEnvelope} /></a>
                        <a href='https://www.instagram.com/yourrriiiiii' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faInstagram} /></a>
                        <a href='https://www.github.com/Your1405' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faGithub}/></a>
                    </div>
                </div>
                <button className='hamburger-menu' onClick={this.toggleMenu}>
                    { this.state.navVisible ? <FontAwesomeIcon icon={faBars}/> : <FontAwesomeIcon icon={faXmark} />}
                </button>
            </header>
        );
    }
}

export default Header;